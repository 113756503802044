<template>
    <section class="h-100 pt-2 pr-2">
        <div class="row mx-0 h-100">
            <div class="menu-prinicipal h-100">
                <div class="row mx-0 py-2 px-2 border-bottom">
                    <div class="col my-auto pl-0">
                        <el-input v-model="buscar" clearable placeholder="Buscar pedido" size="small" class="br-20" />
                    </div>
                    <el-tooltip class="item" effect="light" content="Filtro" placement="bottom">
                        <div class="btn-action border cr-pointer ml-2 d-middle-center " @click="openFiltro">
                            <i class="icon-filter-outline f-26" />
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="$can('botones_abastecimiento_crear_pedido')" class="item" effect="light" content="Crear pedido" placement="bottom">
                        <div class="btn-red ml-2 d-middle-center br-8" @click="crearPedido">
                            <i class="icon-plus f-18 text-white" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="row mx-0 align-items-center border-right border-bottom justify-center" style="height:49px;">
                    <el-tooltip class="item" effect="light" content="Pedidos recibidos hoy" placement="bottom">
                        <div class="br-20 bg-fondo border px-2 mx-1 text-general">
                            <i class="icon-pedidos f-20" />
                            <span class="mx-2">{{ indicadores.recibidos }}</span>
                        </div>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="Pedidos cancelados hoy" placement="bottom">
                        <div class="br-20 bg-fondo border px-2 mx-1 text-general">
                            <i class="icon-cancel-outline f-20" />
                            <span class="mx-2">{{ indicadores.cancelados }}</span>
                        </div>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="Pedidos entregados hoy" placement="bottom">
                        <div class="br-20 bg-fondo border px-2 mx-1 text-general">
                            <i class="icon-shopping f-20" />
                            <span class="mx-2">{{ indicadores.entregados }}</span>
                        </div>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="Ventas del dia" placement="bottom">
                        <div class="br-20 bg-fondo border px-2 mx-1 text-general d-middle">
                            <i class="icon-coin f-20" />
                            <span class="mx-2">{{ convertMoney(indicadores.total_venta,1) }}</span>
                        </div>
                    </el-tooltip>
                </div>
                <div class="ml-2 overflow-auto custom-scroll border-right" style="width:400px;height:calc(100% - 105px);">
                    <div>
                        <div class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-content-between">
                            <span>Sin confirmar</span>
                            <div class="d-middle">
                                <div class="br-20 bg-white f-600 px-2">
                                    {{ fill_sin_confirmar.length }}
                                </div>
                                <i class="cr-pointer ml-2 f-18" :class="ver_sinconfirmar ? 'icon-angle-down' : 'icon-angle-up'" @click="ver_sinconfirmar = !ver_sinconfirmar" />
                            </div>
                        </div>
                        <div
                        v-for="(sc, idx) in fill_sin_confirmar" :key="idx"
                        class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2"
                        :class="{'d-none':ver_sinconfirmar}"
                        @click="seleccionar_pedido(sc.id)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${activo==sc.id?'bg-general':''}`" style="height:80px;" />
                            <div :class="`col-11 px-0 py-2 tres-puntos f-15 br-12 ${activo==sc.id?'bg-light-f5':''}`">
                                <div class="row mx-0 d-middle justify-content-around">
                                    <div v-show="sc.id_pedido" class="icono text-dark">
                                        <i class="icon-account-plus" />
                                    </div>
                                    <div class="col-4 px-2 d-middle">
                                        <p class="tres-puntos f-600 text-dark">ID. {{ sc.id }}</p>
                                    </div>
                                    <div class="col-4 px-1 f-600 text-dark" :class="{'col-6':sc.cant_mensajes == 0}" style="text-align: end;">
                                        {{ convertMoney(sc.valor_final,sc.idm_moneda) }}
                                    </div>
                                    <div v-if="sc.cant_mensajes > 0" class="pt-2 col pr-0">
                                        <el-badge :value="sc.cant_mensajes" class="item ml-3">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                    </div>
                                </div>
                                <div class="row px-2 mx-0 my-1 text-general">
                                    <div class="col-8 px-2">
                                        <p v-if="sc.fecha_entrega" class="tres-puntos">{{ sc.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                        <p v-else class="tres-puntos">No programado</p>
                                    </div>
                                    <div class="col-4 text-right px-1">
                                        <b class="mr-1">
                                            {{ sc.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-1 mx-0">
                                    <div class="col px-2 d-middle text-general">
                                        <i class="icon-location f-14" />
                                        <p class="tres-puntos">{{ sc.direccion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-content-between">
                            <span>En Reclamo</span>
                            <div class="d-middle">
                                <div class="br-20 bg-white f-600 px-2">
                                    {{ fill_en_reclamo.length }}
                                </div>
                                <i class="cr-pointer ml-2 f-18" :class="ver_enreclamo ? 'icon-angle-down' : 'icon-angle-up'" @click="ver_enreclamo = !ver_enreclamo" />
                            </div>
                        </div>
                        <div
                        v-for="(sc, idx) in fill_en_reclamo" :key="idx"
                        class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2"
                        :class="{'d-none':ver_enreclamo}"
                        @click="seleccionar_pedido(sc.id)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${activo==sc.id?'bg-general':''}`" style="height:80px;" />
                            <div :class="`col-11 px-0 py-2 tres-puntos f-15 br-12 ${activo==sc.id?'bg-light-f5':''}`">
                                <div class="row mx-0 d-middle justify-content-around">
                                    <div v-show="sc.id_pedido" class="icono text-dark">
                                        <i class="icon-account-plus" />
                                    </div>
                                    <div class="col-4 px-2 d-middle">
                                        <p class="tres-puntos f-600 text-dark">ID. {{ sc.id }}</p>
                                    </div>
                                    <div class="col-4 px-1 f-600 text-dark" :class="{'col-6':sc.cant_mensajes == 0}" style="text-align: end;">
                                        {{ convertMoney(sc.valor_final,sc.idm_moneda) }}
                                    </div>
                                    <div v-if="sc.cant_mensajes > 0" class="pt-2 col pr-0">
                                        <el-badge :value="sc.cant_mensajes" class="item ml-3">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                    </div>
                                </div>
                                <div class="row px-2 mx-0 my-1 text-general">
                                    <div class="col-8 px-2">
                                        <p class="tres-puntos">{{ sc.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                    <div class="col-4 px-1">
                                        <b class="mr-1">
                                            {{ sc.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-2 mx-0 text-general">
                                    <div class="col px-2 d-middle">
                                        <i class="icon-location f-14" />
                                        <p class="tres-puntos">{{ sc.direccion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-content-between">
                            <span>Confirmados</span>
                            <div class="d-middle">
                                <div class="br-20 bg-white f-600 px-2">
                                    {{ fill_confirmados.length }}
                                </div>
                                <i class="cr-pointer ml-2 f-18" :class="ver_confirmados ? 'icon-angle-down' : 'icon-angle-up'" @click="ver_confirmados = !ver_confirmados" />
                            </div>
                        </div>
                        <div
                        v-for="(c, idx) in fill_confirmados" :key="idx"
                        class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2"
                        :class="{'d-none':ver_confirmados}"
                        @click="seleccionar_pedido(c.id)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${activo==c.id?'bg-general':''}`" style="height:80px;" />
                            <div
                            class="col-11 px-0 py-2 tres-puntos f-15 br-12"
                            :class="`${activo==c.id?'bg-light-f5':''}`"
                            >
                                <div class="row mx-0 d-middle justify-content-around">
                                    <div v-show="c.manual === 1" class="icono text-danger">
                                        <i class="icon-warehouse" />
                                    </div>
                                    <div v-show="c.id_pedido" class="icono text-dark">
                                        <i class="icon-account-plus" />
                                    </div>
                                    <div class="col-4 px-2 d-middle">
                                        <p class="tres-puntos f-600 text-dark">ID. {{ c.id }}</p>
                                    </div>
                                    <div class="col-4 px-1 f-600 text-dark" :class="{'col-6':c.cant_mensajes == 0}" style="text-align: end;">
                                        {{ convertMoney(c.valor_final,c.idm_moneda) }}
                                    </div>
                                    <div v-if="c.cant_mensajes > 0" class="pt-2">
                                        <el-badge :value="c.cant_mensajes" class="item mx-2 mr-4">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                    </div>
                                </div>
                                <div class="row px-2 mx-0 my-1 text-general">
                                    <div class="col-8 px-2">
                                        <p class="tres-puntos">{{ c.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                    <div class="col-4 px-1 text-right">
                                        <b class="mr-1">
                                            {{ c.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-1 mx-0 text-general">
                                    <div class="col px-2 d-middle">
                                        <i class="icon-location f-14" />
                                        <p class="tres-puntos">{{ c.direccion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                    <div>
                        <div class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-content-between">
                            <span>Pendiente confirmación cliente</span>
                            <div class="d-middle">
                                <div class="br-20 bg-white f-600 px-2">
                                    {{ fill_pendientes_confirmar.length }}
                                </div>
                                <i class="cr-pointer ml-2 f-18" :class="ver_pendientes_confirmar ? 'icon-angle-down' : 'icon-angle-up'" @click="ver_pendientes_confirmar = !ver_pendientes_confirmar" />
                            </div>
                        </div>
                        <div
                        v-for="(c, idx) in fill_pendientes_confirmar" :key="idx"
                        class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2"
                        :class="{'d-none':ver_pendientes_confirmar}"
                        @click="seleccionar_pedido(c.id)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${activo==c.id?'bg-general':''}`" style="height:80px;" />
                            <div
                            class="col-11 px-0 py-2 tres-puntos f-15 br-12"
                            :class="`${activo==c.id?'bg-light-f5':''}`"
                            >
                                <div class="row mx-0 d-middle justify-content-around">
                                    <div v-show="c.manual === 1" class="icono text-danger">
                                        <i class="icon-warehouse" />
                                    </div>
                                    <div v-show="c.id_pedido" class="icono text-dark">
                                        <i class="icon-account-plus" />
                                    </div>
                                    <div class="col-4 px-2 d-middle">
                                        <p class="tres-puntos f-600 text-dark">ID. {{ c.id }}</p>
                                    </div>
                                    <div class="col-4 px-1 f-600 text-dark" :class="{'col-6':c.cant_mensajes == 0}" style="text-align: end;">
                                        {{ convertMoney(c.valor_final,c.idm_moneda) }}
                                    </div>
                                    <div v-if="c.cant_mensajes > 0" class="pt-2">
                                        <el-badge :value="c.cant_mensajes" class="item mx-2 mr-4">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                    </div>
                                </div>
                                <div class="row px-2 mx-0 my-1 text-general">
                                    <div class="col-8 px-2">
                                        <p class="tres-puntos">{{ c.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                    <div class="col-4 px-1 text-right">
                                        <b class="mr-1">
                                            {{ c.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-1 mx-0 text-general">
                                    <div class="col px-2 d-middle">
                                        <i class="icon-location f-14" />
                                        <p class="tres-puntos">{{ c.direccion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                    <!-- -->
                    <div>
                        <div class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-content-between">
                            <span>Confirmados por el cliente</span>
                            <div class="d-middle">
                                <div class="br-20 bg-white f-600 px-2">
                                    {{ fill_confirmados_clientes.length }}
                                </div>
                                <i class="cr-pointer ml-2 f-18" :class="ver_confirmados_clientes ? 'icon-angle-down' : 'icon-angle-up'" @click="ver_confirmados_clientes = !ver_confirmados_clientes" />
                            </div>
                        </div>
                        <div
                        v-for="(c, idx) in fill_confirmados_clientes" :key="idx"
                        class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2"
                        :class="{'d-none':ver_confirmados_clientes}"
                        @click="seleccionar_pedido(c.id)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${activo==c.id?'bg-general':''}`" style="height:80px;" />
                            <div
                            class="col-11 px-0 py-2 tres-puntos f-15 br-12"
                            :class="`${activo==c.id?'bg-light-f5':''}`"
                            >
                                <div class="row mx-0 d-middle justify-content-around">
                                    <div v-show="c.manual === 1" class="icono text-danger">
                                        <i class="icon-warehouse" />
                                    </div>
                                    <div v-show="c.id_pedido" class="icono text-dark">
                                        <i class="icon-account-plus" />
                                    </div>
                                    <div class="col-4 px-2 d-middle">
                                        <p class="tres-puntos f-600 text-dark">ID. {{ c.id }}</p>
                                    </div>
                                    <div class="col-4 px-1 f-600 text-dark" :class="{'col-6':c.cant_mensajes == 0}" style="text-align: end;">
                                        {{ convertMoney(c.valor_final,c.idm_moneda) }}
                                    </div>
                                    <div v-if="c.cant_mensajes > 0" class="pt-2">
                                        <el-badge :value="c.cant_mensajes" class="item mx-2 mr-4">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                    </div>
                                </div>
                                <div class="row px-2 mx-0 my-1 text-general">
                                    <div class="col-8 px-2">
                                        <p class="tres-puntos">{{ c.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                    <div class="col-4 px-1 text-right">
                                        <b class="mr-1">
                                            {{ c.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-1 mx-0 text-general">
                                    <div class="col px-2 d-middle">
                                        <i class="icon-location f-14" />
                                        <p class="tres-puntos">{{ c.direccion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                    <div>
                        <div class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-content-between">
                            <span>En Alistamento</span>
                            <div class="d-middle">
                                <div class="br-20 bg-white f-600 px-2">
                                    {{ fill_alistados.length }}
                                </div>
                                <i class="cr-pointer ml-2 f-18" :class="ver_alistados ? 'icon-angle-down' : 'icon-angle-up'" @click="ver_alistados = !ver_alistados" />
                            </div>
                        </div>
                        <div
                        v-for="(c, idx) in fill_alistados" :key="idx"
                        class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2"
                        :class="{'d-none':ver_alistados}"
                        @click="seleccionar_pedido(c.id)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${activo==c.id?'bg-general':''}`" style="height:80px;" />
                            <div
                            class="col-11 px-0 py-2 tres-puntos f-15 br-10"
                            :class="`${activo==c.id?'bg-light-f5':''}`"
                            >
                                <div class="row mx-0 d-middle justify-content-around">
                                    <div v-show="c.manual === 1" class="icono text-danger">
                                        <i class="icon-warehouse" />
                                    </div>
                                    <div v-show="c.id_pedido" class="icono text-dark">
                                        <i class="icon-account-plus" />
                                    </div>
                                    <div class="col-4 px-2 d-middle">
                                        <p class="tres-puntos f-600 text-dark">ID. {{ c.id }}</p>
                                    </div>
                                    <div class="col-4 px-1 f-600 text-dark" :class="{'col-6':c.cant_mensajes == 0}" style="text-align: end;">
                                        {{ convertMoney(c.valor_final,c.idm_moneda) }}
                                    </div>
                                    <div v-if="c.cant_mensajes > 0" class="pt-2">
                                        <el-badge :value="c.cant_mensajes" class="item mx-2 mr-4">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                    </div>
                                </div>
                                <div class="row px-2 text-general mx-0 my-1">
                                    <div class="col-8 px-2">
                                        <p class="tres-puntos">{{ c.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                    <div class="col-4 text-right px-1">
                                        <b class="mr-1">
                                            {{ c.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-2 text-general mx-0">
                                    <div class="col px-2 d-middle">
                                        <i class="icon-location f-14" />
                                        <p class="tres-puntos">{{ c.direccion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-content-between">
                            <span>Empacados</span>
                            <div class="d-middle">
                                <div class="br-20 bg-white f-600 px-2">
                                    {{ fill_empacados.length }}
                                </div>
                                <i class="cr-pointer ml-2 f-18" :class="ver_empacados ? 'icon-angle-down' : 'icon-angle-up'" @click="ver_empacados = !ver_empacados" />
                            </div>
                        </div>
                        <div
                        v-for="(c, idx) in fill_empacados" :key="idx"
                        class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2"
                        :class="{'d-none':ver_empacados}"
                        @click="seleccionar_pedido(c.id)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${activo==c.id?'bg-general':''}`" style="height:80px;" />
                            <div
                            class="col-11 px-0 py-2 tres-puntos f-15 br-12"
                            :class="`${activo==c.id?'bg-light-f5':''}`"
                            >
                                <div class="row mx-0 d-middle justify-content-around">
                                    <div v-show="c.manual === 1" class="icono text-danger">
                                        <i class="icon-warehouse" />
                                    </div>
                                    <div v-show="c.id_pedido" class="icono text-dark">
                                        <i class="icon-account-plus" />
                                    </div>
                                    <div class="col-4 px-2 d-middle">
                                        <p class="tres-puntos text-dark f-600">ID. {{ c.id }}</p>
                                    </div>
                                    <div class="col-4 px-1 f-600 text-dark" :class="{'col-6':c.cant_mensajes == 0}" style="text-align: end;">
                                        {{ convertMoney(c.valor_final,c.idm_moneda) }}
                                    </div>
                                    <div v-if="c.cant_mensajes > 0" class="pt-2">
                                        <el-badge :value="c.cant_mensajes" class="item mx-2 mr-4">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                    </div>
                                </div>
                                <div class="row px-2 text-general mx-0 my-1">
                                    <div class="col-8 px-2">
                                        <p class="tres-puntos">{{ c.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                    <div class="col-4 text-right px-1">
                                        <b class="mr-1">
                                            {{ c.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-2 text-general mx-0">
                                    <div class="col-12 px-2 d-middle">
                                        <i class="icon-location f-14" />
                                        <p class="tres-puntos">{{ c.direccion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-content-between">
                            <span>Enviados</span>
                            <div class="d-middle">
                                <div class="br-20 bg-white f-600 px-2">
                                    {{ fill_enviados.length }}
                                </div>
                                <i class="cr-pointer ml-2 f-18" :class="ver_enviados ? 'icon-angle-down' : 'icon-angle-up'" @click="ver_enviados = !ver_enviados" />
                            </div>
                        </div>
                        <div
                        v-for="(e, idx) in fill_enviados" :key="idx"
                        class="row mx-0 d-middle cr-pointer my-2 border-bottom pb-2"
                        :class="{'d-none': ver_enviados}"
                        @click="seleccionar_pedido(e.id)"
                        >
                            <div :class="`mx-2 px-1 br-5 ${activo==e.id?'bg-general':''}`" style="height:80px;" />
                            <div
                            class="col-11 px-0 py-2 tres-puntos f-15 br-12"
                            :class="`${activo==e.id?'bg-light-f5':''}`"
                            >
                                <div class="row mx-0 d-middle justify-content-around">
                                    <div v-show="e.manual === 1" class="icono text-danger">
                                        <i class="icon-warehouse" />
                                    </div>
                                    <div v-show="e.id_pedido" class="icono text-dark">
                                        <i class="icon-account-plus" />
                                    </div>
                                    <div class="col-4 px-2 pl-4 d-middle">
                                        <p class="tres-puntos f-600 text-dark">ID. {{ e.id }}</p>
                                    </div>
                                    <div class="col-4 px-1 f-600 text-dark" :class="{'col-6':e.cant_mensajes == 0}" style="text-align: end;">
                                        {{ convertMoney(e.valor_final,e.idm_moneda) }}
                                    </div>
                                    <div v-if="e.cant_mensajes > 2" class="pt-2">
                                        <el-badge :value="e.cant_mensajes" class="item mx-2 mr-4">
                                            <i class="icon-message-text-outline cr-pointer f-20 text-gr-general" />
                                        </el-badge>
                                    </div>
                                </div>
                                <div class="row px-2 text-general mx-0 my-1">
                                    <div class="col-8 px-2">
                                        <p class="tres-puntos">{{ e.fecha_entrega | helper-fecha('DD MMM hh:mm a') }}</p>
                                    </div>
                                    <div class="col-4 px-1">
                                        <b class="mr-1">
                                            {{ e.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-2 text-general mx-0">
                                    <div class="col-12 px-2 d-middle">
                                        <i class="icon-location f-14" />
                                        <p class="tres-puntos">{{ e.direccion }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="sin_confirmar.length === 0 && confirmados.length === 0 && enviados.length === 0 && alistados.length === 0 && empacados.length === 0" class="row mx-0 mt-4 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="110" src="/img/no-imagen/canasta.svg" alt="" />
                            </div>
                            <p>Esperamos que estés preparado ¡Ya vienen los pedidos!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 col menu-prinicipal h-100" style="width: calc(100vw - 560px);">
                <listado-productos />
            </div>
        </div>
        <modal-crear-pedido ref="modalCrearPedido" />
        <filtro-pedidos ref="modalFiltro" :opcion="1" @filtrar="accion_filtro" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-admin'
import moment from 'moment'
export default {
    sockets: {
        pedidos(obj){
            //console.log(obj);
            this.verificar_pedido(obj)
        },
        pedidos_reclamo(obj){
            let { id } = JSON.parse(obj)
            if (this.$route.params.id_pedido == id){
                this.sincronizar()
            }
        },
        admin_pedidos_chats(value){ // Emite Cuando se escribe en tabla admin_pedidos_chats
            try {
                const {chat, pedidoChat} = JSON.parse(value)
                // console.log(this.$route.name);
                if(this.$route.name=== 'admin.almacen.pedidos'){// Si el mensaje es de un pedido del admin logueado
                    // console.log(parseInt(this.$route.params.id_pedido), parseInt(chat.id_admin_pedido), (this.$route.params.id_pedido !== parseInt(chat.id_admin_pedido)));
                    if( parseInt(this.$route.params.id_pedido) !== parseInt(chat.id_admin_pedido)){// cuando No tengo abierto  el chat del pedido actual entonces notifico
                        let idx_confirmado = this.confirmados.findIndex(e => e.id == pedidoChat.id)
                        let idx_enviado= this.enviados.findIndex(e => e.id == pedidoChat.id)
                        let idx_sin_confirmar= this.sin_confirmar.findIndex(e => e.id == pedidoChat.id)
                        let idx_alistados= this.alistados.findIndex(e => e.id == pedidoChat.id)
                        let idx_empacados= this.empacados.findIndex(e => e.id == pedidoChat.id)
                        if(idx_confirmado >= 0){
                            let found = this.confirmados[idx_confirmado]
                            found.cant_mensajes = found.cant_mensajes +1
                        }else if(idx_enviado >= 0){
                            let found = this.enviados[idx_enviado]
                            found.cant_mensajes = found.cant_mensajes +1
                        }else if(idx_sin_confirmar >= 0){
                            let found = this.sin_confirmar[idx_sin_confirmar]
                            found.cant_mensajes = found.cant_mensajes +1
                        }else if(idx_alistados >= 0){
                            let found = this.alistados[idx_alistados]
                            found.cant_mensajes = found.cant_mensajes +1
                        }else if(idx_empacados >= 0){
                            let found = this.empacados[idx_empacados]
                            found.cant_mensajes = found.cant_mensajes +1
                        }
                        if(this.pedido && this.pedido.id == chat.id_admin_pedido){
                            this.pedido.cant_mensajes = this.pedido.cant_mensajes + 1
                        }
                    }
                }

            } catch (e){
                this.error_catch(e)
            }
        }
    },
    middleware:['auth'],
    metaInfo: () =>({ title: 'Pedidos'}),
    components: {
        listadoProductos: () => import('./listado-productos'),
        modalCrearPedido: () => import('./partials/modalCrearPedido'),
    },
    data(){
        return {
            buscar: '',
            activo: -1,
            ver_sinconfirmar:false,
            ver_enreclamo:true,
            ver_confirmados:true,
            ver_alistados:true,
            ver_empacados:true,
            ver_enviados:true,
            ver_confirmados_clientes: true,
            ver_pendientes_confirmar: true,

        }
    },
    computed:{
        ...mapGetters({
            indicadores: 'pedidos/pedidos_admin/indicadores',
            sin_confirmar: 'pedidos/pedidos_admin/sin_confirmar',
            en_reclamo: 'pedidos/pedidos_admin/en_reclamo',
            confirmados: 'pedidos/pedidos_admin/confirmados',
            alistados: 'pedidos/pedidos_admin/alistados',
            empacados: 'pedidos/pedidos_admin/empacados',
            enviados: 'pedidos/pedidos_admin/enviados',
            confirmados_clientes: 'pedidos/pedidos_admin/confirmados_clientes',
            pendientes_confirmar: 'pedidos/pedidos_admin/pendientes_confirmar',
            pedido: 'pedidos/pedidos_admin/pedido', // solo para actualizar icono chat
            id_cedis:'cedis/id_cedis'


        }),
        rol(){
            return this.$usuario.rol_nombre
        },
        id_pedido(){
            return this.$route.params.id_pedido
        },
        fill_sin_confirmar(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.sin_confirmar.filter(el =>
                    String(el.id).includes(this.buscar.toLowerCase()) ||
                    el.nombre.toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase())
                )
            }else{
                return this.sin_confirmar
            }
        },
        fill_confirmados(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.confirmados.filter(el =>
                    String(el.id).includes(this.buscar.toLowerCase()) ||
                    el.nombre.toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase())
                )
            }else{
                return this.confirmados
            }
        },
        fill_alistados(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.alistados.filter(el =>
                    String(el.id).includes(this.buscar.toLowerCase()) ||
                    el.nombre.toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase())
                )
            }else{
                return this.alistados
            }
        },
        fill_empacados(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.empacados.filter(el =>
                    String(el.id).includes(this.buscar.toLowerCase()) ||
                    el.nombre.toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase())
                )
            }else{
                return this.empacados
            }
        },
        fill_enviados(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.enviados.filter(el =>
                    String(el.id).includes(this.buscar.toLowerCase()) ||
                    el.nombre.toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase())
                )
            }else{
                return this.enviados
            }
        },
        fill_en_reclamo(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.en_reclamo.filter(el =>
                    String(el.id).includes(this.buscar.toLowerCase()) ||
                    el.nombre.toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase())
                )
            }else{
                return this.en_reclamo
            }
        },
        fill_confirmados_clientes(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.confirmados_clientes.filter(el =>
                    String(el.id).includes(this.buscar.toLowerCase()) ||
                    el.nombre.toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase())
                )
            }else{
                return this.confirmados_clientes
            }
        },
        fill_pendientes_confirmar(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.pendientes_confirmar.filter(el =>
                    String(el.id).includes(this.buscar.toLowerCase()) ||
                    el.nombre.toLowerCase().includes(this.buscar.toLowerCase()) ||
                    String(el.direccion).toLowerCase().includes(this.buscar.toLowerCase())
                )
            }else{
                return this.pendientes_confirmar
            }
        },
    },
    watch:{
        async id_pedido(val){
            if(val){
                //console.log('aca22222222222');
                // this.sincronizar()
                // await this.$store.dispatch('pedidos/pedidos_admin/ver_pedido', Number(val))

                await this.validar_estado_pedido(Number(this.$route.params.id_pedido))
                this.$store.commit('pedidos/pedidos_admin/set_id_pedido', Number(this.$route.params.id_pedido))
                //await this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.$route.params.id_pedido))
                //this.$store.dispatch('pedidos/pedidos_admin/pedidos_historial', Number(this.$route.params.id_pedido))
                //this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.$route.params.id_pedido))
                if(this.pedido.pago_estado === 2){
                    this.$store.dispatch('pedidos/pedidos_admin/pedido_credito', this.id_pedido)
                }
                this.activo = this.$route.params.id_pedido

            }
        },
        id_cedis(val,oldVal){
            if(val != null){
                if(oldVal == null) return
                this.$store.dispatch('pedidos/pedidos_admin/listar_pedidos',val)
                this.lista_indicadores()
                this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
                this.activo = -1
                if(this.$route.params.id_pedido != null){
                    this.$route.params.id_pedido = null
                    this.$router.push({name:'admin.almacen.pedidos'})
                }
            }

        }
    },
    mounted(){
        this.sincronizar()
    },
    methods: {
        async sincronizar(){
            //console.log('mounted');
            let that = this
            if(this.id_cedis === null){
                setTimeout(function(){
                    that.lista_indicadores()
                    that.listar_pedidos()
                }, 500);
            }else{
                that.lista_indicadores()
                that.listar_pedidos()
            }

            if(this.$route.params.id_pedido){
                await this.$store.dispatch('pedidos/pedidos_admin/ver_pedido', Number(this.$route.params.id_pedido))



                await this.validar_estado_pedido(Number(this.$route.params.id_pedido))
                this.$store.commit('pedidos/pedidos_admin/set_id_pedido', Number(this.$route.params.id_pedido))
                // await this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.$route.params.id_pedido))
                // this.$store.dispatch('pedidos/pedidos_admin/pedidos_historial', Number(this.$route.params.id_pedido))
                // this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.$route.params.id_pedido))
                if(this.pedido.pago_estado === 2){
                    this.$store.dispatch('pedidos/pedidos_admin/pedido_credito', this.id_pedido)
                }
                this.activo = this.$route.params.id_pedido
            }else{
                this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
            }
        },
        verificar_pedido(item){
            const {id,tipo,data} = JSON.parse(item)

            if(data.id_user === this.$usuario.id){
                if(tipo === 332){
                    if(id == this.id_pedido){
                        this.$route.params.id_pedido = null
                        this.$router.push({name:'admin.almacen.pedidos'})
                        this.sincronizar()
                    }
                }else if(tipo === 331){
                    this.lista_indicadores()
                    this.listar_pedidos()
                }else if(tipo === 0){
                    this.listar_pedidos()
                    this.sincronizar()
                }
            }


        },
        async validar_estado_pedido(id){
            const {data} = await Pedidos.validar_estado(id)
            if([1,2,201,202,3,31].indexOf(data.estado) < 0){
                this.$router.push({name:'admin.almacen.historial'})
            }
        },
        async lista_indicadores(){
            try {
                await this.$store.dispatch('pedidos/pedidos_admin/pedidos_indicadores',this.id_cedis)
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_pedidos(){
            try {

                this.$store.dispatch('pedidos/pedidos_admin/listar_pedidos',this.id_cedis)
            } catch (e){
                this.error_catch(e)
            }
        },
        seleccionar_pedido(id){
            this.activo = id
            if(id !== Number(this.$route.params.id_pedido)){
                this.$router.push({params:{id_pedido:id}})
            }
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', id)
        },
        tiempo(fecha){
            return moment(fecha).fromNow()
        },
        crearPedido(){
            this.$refs.modalCrearPedido.toggle()
        },
        accion_filtro(obj){

            this.$store.commit('pedidos/pedidos_admin/set_filtro', obj)

            this.listar_pedidos()
        },
        openFiltro(){
            this.$refs.modalFiltro.open()
        }
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    background: #fff;
}
.bg-blue{
    background: #0D4DFF;
}
.icono{
    padding-left: 5px;
    padding-right: 5px;
    i{
        font-size: 20px;
        position: relative;
    }
}
</style>
